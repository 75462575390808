import React from "react"
import { Container } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import AdvertContact from "../components/advert"

const ContactPage = () => (
  <>
    <Header />
    <SEO title="Contact" />
    <Container className="mt-4">
      <h1>Contact Us</h1>
      <AdvertContact />
    </Container>
    <Footer />
  </>
)

export default ContactPage